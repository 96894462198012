<template>
  <div>
    <b-row>
      <b-col sm="12">
        <Select title="Conta Bancária" field="bankAccount" :formName="formName" :required="true"
          v-model="billetConfig.bankAccount" url="api/v1/finance/bank-account/select-all"
          :showNewRegister="false">
        </Select>
      </b-col>
      <b-col sm="12">
        <InputText title="Token" field="token" :formName="formName" :required="true" :maxLength="500"
          v-model="billetConfig.tokenAccount" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="saveBilletConfig" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveBilletConfig" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import BilletConfig from "@/components/modules/billing/billet-config/BilletConfig.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "BilletConfigCreateUpdate",
  props: {
    value: Object,
  },
  components: { Button, InputText, Select },
  data() {
    return {
      formName: "billetConfigCreateUpdate",
      billetConfig: new BilletConfig(),
      urlCreate: "/api/v1/billing/billet-config/create",
      urlUpdate: "/api/v1/billing/billet-config/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["removeLoading", "hideModal", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveBilletConfig() {
      if (this.billetConfig.id) {
        let params = { url: this.urlUpdate, obj: this.billetConfig };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["saveBilletConfig"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.billetConfig };
        this.postApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["saveBilletConfig"]);
        });
      }
    },
    update(data) {
      this.billetConfig = new BilletConfig();
      this.billetConfig.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "billetConfigCreate") {
          this.resetValidation(this.formName);
          this.billetConfig = new BilletConfig();
        }
        if (event.name == "billetConfigUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
