<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="btnBilletConfigCreate" title="Integração" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="customerCreate" :clicked="create" />
      </div>
    </ViewTemplateWithTable>
    <Modal title="Integração" :width="600" :height="250" v-show="showModal('billetConfigCreateUpdate')">
      <BilletConfigCreateUpdate />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";

import BilletConfigCreateUpdate from '../../../components/modules/billing/billet-config/BilletConfigCreateUpdate.vue'

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "BilletConfigListView",
  components: {
    ViewTemplateWithTable,
    Modal,
    Button, BilletConfigCreateUpdate
  },
  data() {
    return {
      panel: {
        module: "Boleto",
        title: "Integração",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/billing/billet-config/get-all",
        urlDeleteAllApi: "/api/v1/billing/billet-config/delete",
        showChecks: true,
        headerTable: [
          {
            field: "urlLogo",
            container: "nixloc-bank-logo",
            type: "image",
            classCssBody: "img-bank",
          },
          {
            field: "bankAccountName",
            title: "Conta Bancária",
            type: "link",
            eventName: "billetConfigUpdate",
            iconSearch: true,
          },
          {
            field: "tokenAccount",
            title: "Token",
            type: "text",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("billetConfigCreateUpdate");
      this.addEvent({ name: "billetConfigCreate" });
      this.removeLoading(["btnBilletConfigCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "billetConfigUpdate")
          this.openModal("billetConfigCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
