export default class BilletConfig {
    constructor() {
        this.id = "";
        this.bankAccount = { id: "", content: "" };
        this.tokenAccount = "";
    }
    update(data) {
        this.id = data.id;
        this.bankAccount = { id: data.bankAccount.id, content: data.bankAccount.name };
        this.tokenAccount = data.tokenAccount;
    }
}